import React, { useState } from "react";
import {GoGrabber, GoX} from 'react-icons/go';

function Navbar () {
    const [navBar, setNavbar] = useState(false)
    const handleClick = () => {
        setNavbar(!navBar)
    }
       
    
    
    return (
        <div className="bg-green-600 max-w-[1440px] w-full h-20 shadow-lg">
            <div className="text-white flex justify-between mx-3 py-5 items-center md:mx-20">
                <h1 className="text-2xl md:text-4xl font-bold ">SK.</h1>
                <input type='search' name='search' placeholder="Search" className="rounded-xl pl-5 shadow-md text-slate-500 w-[60%] h-8 " />
                
                
                <div className="hidden md:flex items-center ">
                    <li className="list-none hiden mb-4 mr-5 mt-3 font-bold cursor-pointer hover:text-gray-500">Home</li>
                    <li className="list-none hiden mb-4 mr-5 mt-3 font-bold cursor-pointer hover:text-gray-500">Products</li>
                    <li className="list-none hiden mb-4 mr-5 mt-3 font-bold cursor-pointer hover:text-gray-500">Gallery</li>
                    <li className="list-none hiden mb-4 mr-5 mt-3 font-bold cursor-pointer hover:text-gray-500">Contact</li>
                </div>

                <div className="cursor-pointer md:hidden" onClick={handleClick}>
                    {!navBar ? <GoGrabber size={30} /> : <GoX size={30} />}
                </div>
                <div className= {!navBar ? 'fixed left-[-100%]' : 'fixed w-[200px] bg-green-600 p-8 left-0 top-0 z-10 h-full shadow-lg ease-in-out duration-500' }>
                    <h1 className="text-2xl font-bold ">Sk</h1>
                    <ul className="uppercase p-4">
                        <li className="mb-4 font-bold cursor-pointer hover:text-slate-600">Home</li>
                        <li className="mb-4 font-bold cursor-pointer hover:text-slate-600">Products</li>
                        <li className="mb-4 font-bold cursor-pointer hover:text-slate-600">Gallery</li>
                        <li className="mb-4 font-bold cursor-pointer hover:text-slate-600">Contact</li>
                    </ul>
                    
                </div>
               

            </div>

        </div>
    )
}

export default Navbar ;