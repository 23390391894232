import React from "react";
import {GoX} from 'react-icons/go'

function Product () {

const imgUrl = [
'https://scontent.fjog3-1.fna.fbcdn.net/v/t39.30808-6/271888242_1265848340600943_650804973393088044_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=a26aad&_nc_eui2=AeHI3ucFnM99NUiiGXxRnNAEgM2QoFI-74yAzZCgUj7vjDra_2HnPgdemf7H76FOvUvJlnxOJVJjATmGz4sQXGj3&_nc_ohc=jY8Jw1BiZowAX_dWnyh&_nc_ht=scontent.fjog3-1.fna&oh=00_AfCz1zuqvv0cesRr3KFn_ITiuEqyy1MnEL8XYR7NFJAMyw&oe=63D49CF2',
'https://scontent.fjog3-1.fna.fbcdn.net/v/t1.6435-9/37414761_411122639406855_1653672433137221632_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=9267fe&_nc_eui2=AeGakX8KpfRhgHBPnjcVydzXZDl9H46HuK5kOX0fjoe4rk5r0mufpyoyJxntNJmEazPmHeqLz99yj4ROGjykEnM1&_nc_ohc=kVq0iJnkFhMAX8USSHj&tn=pK4VHwbdGLYS6xw5&_nc_ht=scontent.fjog3-1.fna&oh=00_AfDbc_y4fSgZStZ_YKMIq6O2FF3Gs992zWfbpaFTyeZ0IQ&oe=63F764E8'
]
const name = ["Rice Bowl", 'budi' , 's']
const price = ['Rp 10.000', 'Rp.12.000']
return (
<div>
    <div className="mx-4 md:mx-52">
        <h1 className="text-[46px] font-bold md:text-[75px]">Products</h1>
        <div className="flex items-center w-full h-[100px] shadow-2xl rounded-xl bg-[#f4f4f4]">
            <div className="w-[200px] h-14 bg-white shadow-md hover:bg-green-600 hover:text-white font-bold flex justify-center items-center mx-4 rounded-xl">
                <p className="mr-3">Rice Bowl</p>
                <GoX />
            </div>
            <div className="w-[200px] h-14 bg-white shadow-md hover:bg-green-600 hover:text-white font-bold flex justify-center items-center mx-4 rounded-xl">
                <p className="mr-3">Rice Bowl</p>
                <GoX />
            </div>
            <div className="w-[200px] h-14 bg-white shadow-md hover:bg-green-600 hover:text-white font-bold hidden md:flex justify-center items-center mx-4 rounded-xl">
                <p className="mr-3">Rice Bowl</p>
                <GoX />
            </div>
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-4 ">
        <div className="w-[300px] md:w-[250px] h-auto justify-center items-center shadow-md mt-8 md:mx-0">
            <img src={imgUrl[0]} alt="" className="p-2 rounded-xl w-cover" />
            <h3 className="font-bold text-gray-500 p-4">{name[0]}</h3>
            <h2 className="font-bold text-xl text-green-600 m-3">{price[0]}</h2>
        </div>
        <div className="w-[300px] md:w-[250px] h-auto justify-center items-center shadow-md mt-8 md:mx-0">
            <img src={imgUrl[0]} alt="" className="p-2 rounded-xl" />
            <h3 className="font-bold text-gray-500 p-4">{name[0]}</h3>
            <h2 className="font-bold text-xl text-green-600 m-3">{price[0]}</h2>
        </div>
        <div className="w-[300px] md:w-[250px] h-auto justify-center items-center shadow-md mt-8 md:mx-0">
            <img src={imgUrl[0]} alt="" className="p-2 rounded-xl" />
            <h3 className="font-bold text-gray-500 p-4">{name[0]}</h3>
            <h2 className="font-bold text-xl text-green-600 m-3">{price[0]}</h2>
        </div>
        <div className="w-[300px] md:w-[250px] h-auto justify-center items-center shadow-md mt-8 md:mx-0">
            <img src={imgUrl[0]} alt="" className="p-2 rounded-xl" />
            <h3 className="font-bold text-gray-500 p-4">{name[0]}</h3>
            <h2 className="font-bold text-xl text-green-600 m-3">{price[0]}</h2>
        </div>
        <div className="w-[300px] md:w-[250px] h-auto justify-center items-center shadow-md mt-8 md:mx-0">
            <img src={imgUrl[0]} alt="" className="p-2 rounded-xl" />
            <h3 className="font-bold text-gray-500 p-4">{name[0]}</h3>
            <h2 className="font-bold text-xl text-green-600 m-3">{price[0]}</h2>
        </div>
        <div className="w-[300px] md:w-[250px] h-auto justify-center items-center shadow-md mt-8 md:mx-0">
            <img src={imgUrl[0]} alt="" className="p-2 rounded-xl" />
            <h3 className="font-bold text-gray-500 p-4">{name[0]}</h3>
            <h2 className="font-bold text-xl text-green-600 m-3">{price[0]}</h2>
        </div>
        </div>
        <hr className="mt-20 border-solid border-2 border-slate-300 "></hr>
    </div>
    

</div>


)
}

export default Product;