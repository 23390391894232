import React from "react";



function Gallery () {
    const imgGallery = [
        'https://i.pinimg.com/564x/cf/68/1c/cf681ca08b130a55f3346c466b7e512b.jpg',
        'https://scontent.fjog3-1.fna.fbcdn.net/v/t1.6435-9/33079970_366703557182097_2814826586891616256_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=9267fe&_nc_eui2=AeFV8KKuH6nCaGGHDVTfaRpOxNevGV6C9jHE168ZXoL2MbXbjelBIEXE4U9hqZEW4rO_5yx3kc9Fd_c4QQ5Eym7N&_nc_ohc=VXy6HzYcCeEAX-1GtpG&tn=pK4VHwbdGLYS6xw5&_nc_ht=scontent.fjog3-1.fna&oh=00_AfCUHAAyRkTPSRPP7YWPPlUN5mgyvTzUKGlnUaAHC010hw&oe=63F745AD',
        'https://scontent.fjog3-1.fna.fbcdn.net/v/t39.30808-6/271888242_1265848340600943_650804973393088044_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=a26aad&_nc_eui2=AeHI3ucFnM99NUiiGXxRnNAEgM2QoFI-74yAzZCgUj7vjDra_2HnPgdemf7H76FOvUvJlnxOJVJjATmGz4sQXGj3&_nc_ohc=jY8Jw1BiZowAX_dWnyh&_nc_ht=scontent.fjog3-1.fna&oh=00_AfCz1zuqvv0cesRr3KFn_ITiuEqyy1MnEL8XYR7NFJAMyw&oe=63D49CF2',
        'https://scontent.fjog3-1.fna.fbcdn.net/v/t39.30808-6/241233065_1179935715858873_5385828951445295171_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=e3f864&_nc_eui2=AeEHlE3ybG4oGQAiCmELuPmUiztaH1Cz5PeLO1ofULPk93nHNBGt2nZ_ub-REZJYICF5tbOBWnQZ4gBVyLVO-zxt&_nc_ohc=Pp0bx8AsIr0AX-9rU8f&_nc_ht=scontent.fjog3-1.fna&oh=00_AfDelLO0c9fK9C9gOFFOgZLCN0GHHY98vx0b8ItJwnpCZA&oe=63D41529',


    ]   

    return(
        <div className="mx-5 md:mx-52">
            <h1 className="text-2xl font-bold mt-10">Galley</h1>
             <div className="  grid grid-rows-3 grid-cols-2 my-20">
            <img src={imgGallery[0]} alt="" className="p-2 rounded-xl grid row-span-2 " />
            <img src={imgGallery[1]} alt="" className="p-2 rounded-xl" />
            <img src={imgGallery[2]} alt="" className="p-2 rounded-xl" />
            <img src={imgGallery[3]} alt="" className="p-2 rounded-xl" />
            <img src={imgGallery[2]} alt="" className="p-2 rounded-xl" />
            <img src={imgGallery[1]} alt="" className="p-2 rounded-xl" />
        </div>

        </div>
       
    )
}

export default Gallery;