import React from "react";
import Slider from './components/Slider';
import Navbar from './components/Navbar';
import Product from './components/Product';
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
function App () {
  return(
    <div>
      <Navbar />
      <Slider />
      <Product />
      <Gallery />
      <Footer />
    </div>
  )
}

export default App;
