import React,{useState} from "react";
import {BsChevronCompactLeft,BsChevronCompactRight} from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';



function Slider() {
    
const slides = [
    {

        url: 'https://scontent.fjog3-1.fna.fbcdn.net/v/t39.30808-6/273008262_1276445429541234_5064876571797811391_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=a26aad&_nc_eui2=AeEPMfOkcPq-60p39c6721pyj8AiT2u__mKPwCJPa7_-YvJToPelmgFj5CCKuUTtThWofi_yZw7ndBGyjWZhxQaO&_nc_ohc=lnF7MZ0MXLUAX8Gcryx&_nc_ht=scontent.fjog3-1.fna&oh=00_AfBZNymCTz5LJQsvzmxT9FTOE1pfP6zTbdX13WK2aInnJA&oe=63D47898',
    },
    {
        url: 'https://scontent.fjog3-1.fna.fbcdn.net/v/t39.30808-6/279316134_1333005960551847_2181041140800580122_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=a26aad&_nc_eui2=AeF6kfduoF32OVTCKHTy7x3CULYSkX9VIclQthKRf1UhyZI5rTZBBhUxskMb4Nkbc28xuYwuPi88qNbcps8mtYEd&_nc_ohc=F5dh1j6Ize0AX9sOeIj&_nc_ht=scontent.fjog3-1.fna&oh=00_AfAEE5NQ2RhqrIOss6BAOhfXO4bJ3tOpCnRCXIuQ3SHNSg&oe=63D3CE3C',
        
    },
    {
        url: 'https://scontent.fjog3-1.fna.fbcdn.net/v/t39.30808-6/273041991_1275956279590149_4480082114819032930_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=a26aad&_nc_eui2=AeF-yYD205gQWf4WcLDTBc23_efFKmkg7YH958UqaSDtgZKauvK-oCiO_9jX463WQVSxkQgdafvjyEd5m7Vav7Kr&_nc_ohc=wUWBxQzl_7MAX_nXVMz&tn=pK4VHwbdGLYS6xw5&_nc_ht=scontent.fjog3-1.fna&oh=00_AfBmgWVxoHiagc4rGtmjBqQakpTV6jzVOpkIfEfbRBkWEw&oe=63D3EC9A',
    }

];

const [currentIndex, setCurrentIndex] = useState(0)

const prevSlide  = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    
}

const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1 ;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);

}
const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
}

    return(
        <div className="max-w-[1400] h-[650px] w-full md:w-[1200px] m-auto py-16 px-4 relative group" >
            <div style={{backgroundImage:`url(${slides[currentIndex].url})`}} className="w-full h-full rounded-2xl bg-cover duration-700 ">
            </div>
            <div className="hidden group-hover:block absolute top-[45%] -translate-x-0 translate-y-[50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                <BsChevronCompactLeft onClick={prevSlide} size={30} />
            </div>
            <div className="hidden group-hover:block absolute top-[45%] -translate-x-0 translate-y-[50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                <BsChevronCompactRight onClick={nextSlide} size={30} />
            </div>
            <div className="flex top-4 justify-center py-2">
                {slides.map((slide,slideIndex) => (
                    <div 
                    key={slideIndex}
                    onClick={() => goToSlide(slideIndex)}
                    className=" text-xl cursor-pointer">
                        <RxDotFilled />
                    </div>
                ))}
                
            </div>
        </div>
    )
}

export default Slider;