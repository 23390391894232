import React from "react";
import { ImFacebook2, ImInstagram, ImWhatsapp,ImLocation} from "react-icons/im";

function Footer () {
    return(
        <div className=" w-full h-auto  bg-green-600 text-white">
           <div className="flex flex-col p-5 md:mx-52 md:flex-row justify-between">
            <div>
            <ul className="my-5 uppercase ">
                <li className="font-bold mb-2">Home</li>
                <li className="font-bold mb-2">Products</li>
                <li className="font-bold mb-2">Gallery</li>
                <li className="font-bold mb-2">Contact</li>
            </ul>
            </div>
           <div className="my-5 md:text-center">
            <h1 className="mb-5 md:text-5xl md:font-bold">Say Hay</h1>
            <p className="mb-5 font-bold">"Ngopi bersama Kodok dan Jangkrik"</p>
            <div className="mb-5">
            <input className="pl-3 rounded-xl h-10 w-72" type="email" name="email" placeholder="email" />
            </div>
            <button className="py-2 px-10 font-bold cursor-pointer hover:bg-slate-300 border-solid border-white rounded-xl border-2">submit</button>
           </div>
           <div className="my-5">
           <ImLocation size={20}/>
           <p className="my-4 font-bold">Ngrancah , Imogiri,Bantul, YK</p>
           </div>

           </div>
           <div className="w-full md:mx-52 p-5">
           <h1 className="text-3xl font-bold">SK.</h1>
            <div className="flex">
                <ImInstagram className="my-2 cursor-pointer mr-4" size={20}/>
                <ImFacebook2 className="my-2 cursor-pointer mr-4" size={20}/>
                <ImWhatsapp className="my-2 cursor-pointer mr-4" size={20}/>
            </div>
           </div>
           <div className="text-center text-sm text-gray-400 py-5">
            <p>©️ Sederek Kopi Imogiri || Create by AnnangBudiS</p>
           </div>
        </div>
    )
}
export default Footer; 